import { Capacitor } from "@capacitor/core"
import axios from "axios"
import { SavePassword } from "capacitor-ios-autofill-save-password"
import { jwtDecode } from "jwt-decode"
import { DateTime } from "luxon"
import { defineStore } from "pinia"
import { computed, nextTick, ref, watch } from "vue"

import type {
    JwtPayload,
    LoginStep2,
    LoginUser,
    RegistrationFormData,
    ResetPasswordFormData
} from "@/interfaces/auth.interface"

import router from "@/router"

import AuthService from "@/services/Auth.service"

import { useInfoStore } from "@/stores/infoStore"
import { useNotificationStore } from "@/stores/notificationStore"
import { useUserStore } from "@/stores/userStore"

export const useAuthStore = defineStore(
    "auth",
    () => {
        const userStore = useUserStore()
        const infoStore = useInfoStore()
        const notificationStore = useNotificationStore()

        const token = ref<string | undefined>()
        const sessionToken = ref<string | undefined>()
        const jwt = ref<JwtPayload | undefined>()

        const session = ref<boolean>(true)
        const authAreaArray = ref<number[]>([])

        const authToken = computed(() => token.value ?? sessionToken.value)

        const login = (jwtString: string): void => {
            jwt.value = jwtDecode(jwtString)

            if (!session.value) {
                sessionToken.value = jwtString
                token.value = undefined
            } else {
                token.value = jwtString
                sessionToken.value = undefined
            }

            void nextTick(notificationStore.checkNotificationPermission)
        }

        const isAuth = async (): Promise<boolean> => {
            try {
                if (!sessionToken.value && !token.value) return false
                if (!jwt.value) {
                    const response = await AuthService.reLogin()
                    login(response.data.jwt)
                }
                if ((jwt.value?.exp ?? 0) <= DateTime.now().toSeconds()) {
                    await logout()
                    return false
                }
                setBearer()
                return true
            } catch (e) {
                console.log("auth:", e)
                return false
            }
        }

        void isAuth()

        const loginEmail = async (email: string) => {
            const response = await AuthService.loginEmail(email)
            return response.data
        }

        const loginPassword = async (
            email: string,
            password: string
        ): Promise<LoginStep2 | LoginUser> => {
            const response = await AuthService.loginPassword(email, password)

            if (Capacitor.getPlatform() === "ios") {
                await SavePassword.promptDialog({
                    password: password,
                    username: email
                })
            }

            if (!("twoFactor" in response.data)) {
                login((response.data as LoginUser).jwt)
            }

            return response.data
        }
        const loginTwoFactor = async (
            email: string,
            password: string,
            otp: string
        ): Promise<LoginUser> => {
            const response = await AuthService.loginTwoFactor(email, password, otp)

            login(response.data.jwt)
            return response.data
        }
        const resetPassword = async (
            resetPasswordFormData: ResetPasswordFormData
        ): Promise<LoginUser> => {
            const response = await AuthService.resetPassword({ ...resetPasswordFormData })

            login(response.data.jwt)

            return response.data
        }

        const register = async (registrationFormData: RegistrationFormData): Promise<void> => {
            await AuthService.register({ ...registrationFormData })
        }

        const checkAuthArea = (area: number) => {
            return authAreaArray.value.includes(area)
        }

        const setBearer = () => {
            const jwtString = sessionToken.value ?? token.value
            if (jwtString) {
                axios.defaults.headers.common["Authorization"] = `Bearer ${jwtString}`
            } else {
                delete axios.defaults.headers.common["Authorization"]
            }
        }

        const fidoRegister = async (password: string) => {
            try {
                await AuthService.fidoRegister(password)
            } catch (error) {
                if (error instanceof DOMException) {
                    if (error.name === "NotAllowedError") {
                        infoStore.addMessage({
                            message: "Du hast die Authentifizierung abgebrochen",
                            title: "Fido Abgebrochen",
                            type: "info"
                        })
                    } else {
                        infoStore.addMessage({
                            message: "Einhorn Tod",
                            title: "Fehler",
                            type: "error"
                        })
                    }
                } else {
                    infoStore.addMessage({
                        message: "Authentifizierung fehlgeschlagen",
                        title: "Fido Fehlgeschlagen",
                        type: "error"
                    })
                }
            }
        }

        const fidoAuthenticate = async (email: string) => {
            try {
                const response = await AuthService.fidoAuthenticate(email)
                login(response.jwt)
                await router.push({ name: "feed" })
            } catch (error) {
                if (error instanceof DOMException) {
                    if (error.name === "NotAllowedError") {
                        infoStore.addMessage({
                            message: "Du hast die Authentifizierung abgebrochen",
                            title: "Fido Abgebrochen",
                            type: "info"
                        })
                    } else {
                        infoStore.addMessage({
                            message: "Einhorn Tod",
                            title: "Fehler",
                            type: "error"
                        })
                    }
                } else {
                    infoStore.addMessage({
                        message: "Authentifizierung fehlgeschlagen",
                        title: "Fido Fehlgeschlagen",
                        type: "error"
                    })
                }
                throw error
            }
        }

        const logout = async (): Promise<void> => {
            userStore.resetUser()
            sessionToken.value = undefined
            token.value = undefined
            jwt.value = undefined
            session.value = true
            await router.push({ name: "login" })
        }

        const logoutEveryDevice = async (): Promise<void> => {
            await AuthService.logout()
            await logout()
        }

        watch([token, sessionToken], setBearer, { immediate: true })

        const fidoAvailable = typeof window.PublicKeyCredential !== "undefined"

        return {
            authAreaArray,
            authToken,
            checkAuthArea,
            fidoAuthenticate,
            fidoAvailable,
            fidoRegister,
            isAuth,
            jwt,
            loginEmail,
            loginPassword,
            loginTwoFactor,
            logout,
            logoutEveryDevice,
            register,
            resetPassword,
            session,
            sessionToken,
            token
        }
    },
    {
        persist: [
            {
                paths: ["sessionToken", "jwt"],
                storage: sessionStorage
            },
            {
                paths: ["token", "session"],
                storage: localStorage
            }
        ]
    }
)
