import { DateTime } from "luxon"
import { defineStore } from "pinia"
import { reactive, ref } from "vue"

import type { infoMessage } from "@/interfaces/info.interface"

export const useInfoStore = defineStore(
    "info",
    () => {
        const messages = reactive<infoMessage[]>([])
        const counter = ref<number>(0)
        const messageTTL = 5000

        const currentMillis = ref<number>(DateTime.now().toMillis())
        const addMessage = (message: infoMessage): void => {
            message.id = counter.value
            message.timeStamp = DateTime.now().toMillis()
            counter.value = counter.value + 1
            messages.unshift(message)
        }

        setInterval(() => {
            messages.forEach((message) => {
                if (
                    (message.timeStamp as number) + messageTTL < DateTime.now().toMillis() &&
                    !message.keep
                )
                    removeMessage(message)
            })
            currentMillis.value = DateTime.now().toMillis()
        }, 50)

        const removeMessage = (message: infoMessage): void => {
            messages.splice(messages.indexOf(message), 1)
        }

        return { addMessage, currentMillis, messageTTL, messages, removeMessage }
    },
    {
        persist: {
            paths: ["messages", "counter"]
        }
    }
)
